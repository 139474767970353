<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-select
                v-model="rewardRule.type"
                :items="['percentage', 'fixed']"
                label="Type"
                :clearable="clearable"
                :rules="rules"
            />
            <v-text-field
                v-model="rewardRule.amount"
                :label="rewardRule.type === 'percentage' ? 'Percentage' : 'Amount'"
                :clearable="clearable"
                type="number"
                :rules="rules"
            />
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="rewardRule.dateFrom"
                        label="Choose from date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="rewardRule.dateFrom"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                />
            </v-menu>
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="rewardRule.dateTo"
                        label="Choose to date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="rewardRule.dateTo"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                />
            </v-menu>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="my-2 white--text" large color="#004cff" :disabled="shouldDisableSubmit()" @click="onSubmit">
                Submit
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import rewardRuleService from '@/services/reward-rule-service'
    import partnerService from '@/services/partner-service'
    import Alert from '@/components/Alert'

    export default {
        name: 'RewardRuleForm',
        components: { Alert },
        props: {
            index: {
                type: Number,
                required: true
            },
            partnerRewardRule: {
                type: Object,
                default: null
            },
            partner: {
                type: Object,
                default: null
            },
            forceMode: {
                type: String,
                default: null
            }
        },
        data: () => {
            return {
                type: null,
                message: null,
                mode: null,
                readOnly: false,
                clearable: true,
                rewardRuleId: null,
                rewardRule: {
                },
                partners: [],
                rules: [
                    v => !!v || 'Required Field'
                ],
                showAlert: false,
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                }
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.forceMode) {
                this.mode = this.forceMode
            }
            this.getAllPartners()
            if (this.mode === 'create' && !this.forceMode) {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.rewardRuleId = this.$route.params.rewardRuleId
            if (this.partnerRewardRule) {
                this.rewardRule = this.partnerRewardRule
                this.rewardRule.partner = this.partner
                this.rewardRuleId = this.rewardRule.id
            } else if (this.rewardRuleId) {
                rewardRuleService.getRewardRuleById(this.rewardRuleId).then(resp => {
                    this.rewardRule = resp.data.rewardRule
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return !this.rewardRule.partner || !this.rewardRule.type || !this.rewardRule.amount || !this.rewardRule.dateFrom || !this.rewardRule.dateTo
            },
            getFieldText(partner) {
                return `${partner.title}`
            },
            onEdit() {
                this.$router.push(`/rewardRule/${this.rewardRuleId}/edit`)
            },
            onSubmit() {
                this.prepareRewardRuleFields()
                if (this.mode === 'edit') {
                    rewardRuleService.editRewardRuleById(this.rewardRuleId, this.rewardRule).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/rewardRules`)
                        }
                        this.$emit('setAlert', 'RewardRule edit was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                } else if (this.mode === 'create') {
                    rewardRuleService.createRewardRule(this.rewardRule).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/rewardRules`)
                        }
                        this.$emit('setAlert', 'Vat creation was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                }
            },
            getAllPartners() {
                if (this.mode !== 'show') {
                    partnerService.getAllPartners().then((res) => {
                        this.partners = res.data.data
                    })
                }
            },
            prepareRewardRuleFields() {
                if (this.rewardRule.id) {
                    delete this.rewardRule.id
                }
                if (this.rewardRule.partner) {
                    this.rewardRule.partner = this.rewardRule.partner.id
                }
            }
        }
    }
</script>

<style scoped>
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
