<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-text-field
                v-model="promo.title"
                :rules="rules"
                label="Title"
                :clearable="clearable"
            />
            <div class="body-editor">
                <span class="body-label">Body</span>
                <editor
                    v-model="promo.content"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import promoService from '@/services/promo-service'
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'PromoForm',
        components: { Editor },
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                available: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                promo: {
                    title: '',
                    content: ''
                },
                editorInit: {
                    selector: 'promo_content',
                    menubar: false,
                    plugins: [
                        'advlist autolink lists charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime table paste help wordcount link'
                    ],
                    automatic_uploads: true,
                    powerpaste_allow_local_images: true,
                    resize: 'both',
                    min_height: 600,
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect fontsizeselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link unlink '
                },
                promoId: null,
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            }
            this.promoId = this.$route.params.promoId
            if (this.promoId) {
                promoService.getPromoById(this.promoId).then(resp => {
                    console.log(resp.data)
                    this.promo = resp.data.data.promo[0]
                })
            }
        },
        methods: {
            shouldDisableSubmit() {
                return !this.promo.title
            },
            onEdit() {
                this.$router.push(`/promos/${this.promoId}/edit`)
            },
            async createPromo() {
                const response = await promoService.createPromo(this.promo)
                if (response.data.id) {
                    this.promoId = response.data.id
                }
                this.$router.push(`/promos`)
            },
            async editPromo() {
                await promoService.editPromoById(this.promoId, this.promo)
                this.$router.push(`/promos`)
            },
            async onSubmit() {
                this.deleteUnecessaryFields()
                if (this.mode === 'edit') {
                    this.editPromo()
                } else if (this.mode === 'create') {
                    this.createPromo()
                }
                this.editImageDescriptions()
            },
            deleteUnecessaryFields() {
                delete this.promo.id
                delete this.promo.createdAt
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
