<template>
    <v-container>
        <v-btn class="my-2" dark small color="#027a00" @click="createPromo">
            <v-icon dark>mdi-plus</v-icon>Create
        </v-btn>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="promos"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.id }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.title }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import promoService from '@/services/promo-service'
    import DeleteButton from '@/components/DeleteButton'
    export default {
        name: 'TeamRoute',
        components: { DeleteButton },
        data: () => {
            return {
                promos: [],
                headers: [
                    {
                        text: 'Id',
                        value: 'id',
                        align: 'left',
                        sortable: true
                    },
                    {
                        text: 'Title',
                        value: 'title',
                        align: 'left',
                        sortable: true
                    }
                ]
            }
        },
        watch: {
            $route(to, from) {
                if (!from.name.includes('Promo')) {
                    this.$store.dispatch('clearFilters')
                }
            }
        },
        created() {
            this.fetchAllPromos()
        },
        methods: {
            handleClick(action, item) {
                this.$router.push(`/promos/${item.id}/${action}`)
            },
            onDelete(item) {
                promoService.deletePromoById(item.id).then(() => {
                    this.fetchAllPromos()
                })
            },
            createPromo() {
                this.$router.push(`/promos/create`)
            },
            fetchAllPromos() {
                promoService.getAllPromos().then(resp => {
                    this.promos = resp.data.data
                })
            },
            mapTrueFalse(value) {
                if (value) {
                    return 'Yes'
                }
                return 'No'
            }
        }
    }
</script>

<style scoped>
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
