import paronApi from '@/utils/paron-api'

export default {
    getAllPromos() {
        return paronApi.get('promo/all')
    },

    getPromoById(id) {
        return paronApi.get(`promo/${id}`)
    },

    createPromo(data) {
        return paronApi.post('promo/new', data)
    },

    editPromoById(id, data) {
        return paronApi.post(`promo/${id}/edit`, data)
    },

    deletePromoById(id) {
        return paronApi.delete(`promo/${id}`)
    }

}
