<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="card-title px-4 mt-1">
                        Filters
                        <v-btn v-if="$store.getters.currentUser.role === 'admin'" class="my-2 mx-2" dark small color="#027a00" @click="createTeam">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <filters-alternative
                            :sort-options="headers.filter(element => element.sortable)"
                            :filters="filterFields"
                            @reloadFilters="reloadFilters"
                            @filterResults="filterResults"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="teams"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.id }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.title }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.address }}</td>
                    <td v-if="!isDealer">
                        <v-btn class="mx-2" dark small :color="props.item.approved ? 'green' : 'red'" outlined @click="toggleApprove(props.item)">
                            <div v-if="props.item.approved">
                                Approved
                            </div>
                            <div v-else>
                                Not Approved
                            </div>
                        </v-btn>
                    </td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button v-if="!isDealer" @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
    </v-container>
</template>

<script>
    import DeleteButton from '@/components/DeleteButton'
    import FiltersAlternative from '@/components/FiltersAlternative'
    import store from '@/store'
    import teamService from '@/services/team-service'

    export default {
        name: 'TeamRoute',
        components: { DeleteButton, FiltersAlternative },
        data: () => {
            return {
                teams: [],
                filterFields: [
                    {
                        text: 'Team',
                        value: 'team',
                        type: 'text',
                        data: null,
                        searchable: true
                    }
                ],
                headers: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Team',
                        value: 'title',
                        align: 'left',
                        type: 'api',
                        data: null
                    },
                    {
                        text: 'Address',
                        value: 'address',
                        searchable: true
                    }
                ],
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                loading: true,
                sortOption: null,
                order: null,
                filters: null
            }
        },
        computed: {
            isDealer() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer'
            }
        },
        watch: {
            page() {
                var path = `/teams?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchFilteredTeams()
            },
            $route(to, from) {
                if (!from.name.includes('Team')) {
                    this.$store.dispatch('clearFilters')
                }
                if (to !== from) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchFilteredTeams()
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.filters = this.$store.getters.getFilters
            this.sortOption = this.$store.getters.getSortOption
            this.order = this.$store.getters.getSortOrder
        },
        methods: {
            reloadFilters() {
                this.order = 'ASC'
                this.sortOption = null
                this.filters = {}
                this.fetchFilteredTeams()
            },
            filterResults(filterObject, sortOption = null, order = 'ASC', page = false) {
                this.sortOption = sortOption
                this.order = order
                this.filters = filterObject
                this.loading = true
                if (page) {
                    this.page = 1
                }
                teamService.getTeamsByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    if (this.page > this.pageCount) {
                        this.page = 1
                    }
                    this.pageCount = resp.data.last_page
                    this.teams = resp.data.data
                    this.loading = false
                })
            },
            toggleApprove(team) {
                if (!team.id) {
                    return
                }
                teamService.toggleApproved(team.id).then(() => {
                    team.approved = !team.approved
                })
            },
            handleClick(mode, item) {
                this.$router.push(`/teams/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                teamService.deleteTeamById(item.id).then(() => {
                    this.fetchFilteredTeams()
                })
            },
            createTeam() {
                this.$router.push(`/teams/create?page=${this.page}`)
            },
            fetchFilteredTeams() {
                this.loading = true
                teamService.getTeamsByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    this.teams = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
