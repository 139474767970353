import paronApi from '@/utils/paron-api'

export default {

    getRewardRuleById(id) {
        return paronApi.get(`rewardRule/${id}`)
    },

    createRewardRule(data) {
        return paronApi.post('rewardRule/new', data)
    },

    editRewardRuleById(id, data) {
        return paronApi.post(`rewardRule/${id}/edit`, data)
    },

    deleteRewardRuleById(id) {
        return paronApi.delete(`rewardRule/${id}`)
    }

}
