<template>
    <v-container class="container">
        <div v-if="mode && !['edit', 'create'].includes(mode) && !isDealer" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="12">
                    <v-select
                        label="Trasaction type"
                        v-model="transaction.type"
                        :items="['prepaid','b2b','regular']"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-text-field
                    v-model="transaction.description"
                    label="Description"
                    :clearable="clearable"
                />
            </v-row>
            <v-row class="selection-form">
                <v-autocomplete
                    v-model="transaction.partner"
                    :items="partners"
                    item-text="title"
                    menu-props="auto"
                    label="Select Partner"
                    prepend-icon="mdi-domain"
                    hide-details
                    single-line
                    :clearable="!readOnly"
                    return-object
                />
                <v-autocomplete
                    v-model="transaction.member"
                    :items="members"
                    :item-text="getMemberInfo"
                    menu-props="auto"
                    label="Select Member"
                    hide-details
                    prepend-icon="mdi-account"
                    single-line
                    :clearable="!readOnly"
                    return-object
                />
            </v-row>
        </v-form>
        <v-row class="mt-15">
            <v-col cols=12>
                <v-row>
                    <v-col cols=6 v-if="transaction.image">
                        <a :href="apiUrl + '/uploads/images/' + transaction.image.imageName">
                            Download the receipt here
                            <v-icon>
                                mdi-cloud-download
                            </v-icon>
                        </a>
                        <v-img :src="apiUrl + '/uploads/images/' + transaction.image.imageName"/>
                    </v-col>
                    <v-col cols=6>
                        <h2> Receipt <a style="font-size: 14px;" :href="receipt.url" target="_blank" v-if="receipt.url"> link </a> </h2>
                        <v-text-field v-model="receipt.initialValue" label="Initial Value"/>
                        <v-text-field v-model="receipt.total" label="Total"/>
                        <v-text-field v-model="receipt.cleanTotal" label="Clean Total"/>
                        <v-autocomplete
                            v-model="receipt.vat"
                            :items="vats"
                            item-text="number"
                            item-value="number"
                            menu-props="auto"
                            label="Vat"
                            hide-details
                            single-line
                            :clearable="!readOnly"
                        />
                        <v-text-field v-model="receipt.fhm" label="FHM"/>
                        <v-text-field v-model="receipt.aa" label="AA"/>
                        <v-text-field v-model="receipt.name" label="Name"/>
                        <v-menu
                            :close-on-content-click="true"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="receipt.date"
                                    label="Choose your receipt date"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    :readonly="readOnly"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="receipt.date"
                                :model-config="modelConfig"
                                class="datepicker"
                                :readonly="readOnly"
                            />
                        </v-menu>
                        <v-checkbox
                            v-model="receipt.scanCompleted"
                            label="Scan Completed"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="mode !== 'show'" class="submit-button-wrapper mt-5">
            <v-btn class="my-5 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import partnerService from '@/services/partner-service'
    import memberService from '@/services/member-service'
    import transactionService from '@/services/transaction-service'
    import vatService from '@/services/vat-service'
    import store from '@/store'

    export default {
        name: 'TransactionForm',
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                transactionId: null,
                receipt: {
                    total: null,
                    cleanTotal: null,
                    date: null,
                    fhm: null,
                    aa: null,
                    vat: null,
                    scanCompleted: false
                },
                transaction: {
                    member: null,
                    partner: null,
                    transactionDate: null,
                    receipt: null,
                    scanCompleted: false
                },
                members: [],
                partners: [],
                vats: [],
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN
            }
        },
        computed: {
            isDealer() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer'
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllMembersAndPartners()
            if (this.mode === 'create') {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.transactionId = this.$route.params.transactionId
            if (this.transactionId) {
                transactionService.getTransactionById(this.transactionId).then(resp => {
                    this.transaction = resp.data.data[0]
                    if (resp.data.data[0].receipt) {
                        this.receipt = resp.data.data[0].receipt
                        this.receipt.date = this.formatDate(this.receipt.date, 'yyyy-MM-DD')
                    }
                })
            }
        },
        methods: {
            shouldDisableSubmit() {
                return !this.transaction.member || !this.transaction.partner
            },
            onEdit() {
                this.$router.push(`/transactions/${this.transactionId}/edit?page=${this.$route.query.page}`)
            },
            onSubmit() {
                this.prepareTransactionFields()
                if (this.receipt.scanCompleted === true) {
                    this.transaction.scanCompleted = true
                }
                if (this.mode === 'edit') {
                    transactionService.editTransactionById(this.transactionId, this.transaction).then(() => {
                        this.$router.push(`/transactions?page=${this.$route.query.page}`)
                    })
                } else if (this.mode === 'create') {
                    transactionService.createTransaction(this.transaction).then(() => {
                        this.$router.push(`/transactions?page=${this.$route.query.page}`)
                    })
                }
            },
            prepareTransactionFields() {
                this.transaction.member = this.transaction.member.id
                this.transaction.partner = this.transaction.partner.id
                this.transaction.receipt = this.receipt
            },
            fetchAllMembersAndPartners() {
                partnerService.getAllPartners().then((resp) => {
                    this.partners = resp.data.data
                })
                memberService.getAllMembers().then((resp) => {
                    this.members = resp.data.data
                })
                vatService.getAllVats().then((resp) => {
                    this.vats = resp.data.data
                })
            },
            getMemberInfo(member) {
                let cardString = ''
                const memberCards = member.cards.filter(card => { return card.active }).map(card => card.number)
                if (memberCards.length > 0) {
                    cardString = ' [' + memberCards.join() + ']'
                }
                return member.firstName + ' ' + member.lastName + cardString
            },
            formatDate(date, format) {
                return moment(date).format(format)
            }
        }
    }
</script>

<style scoped>

.transaction-created-at {
    text-align: center;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
</style>
