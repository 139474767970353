import moment from 'moment'
import jwtDecode from 'jwt-decode'
export default {
    isJwtExpired(jwtToken) {
        if (jwtToken) {
            const decodedJwtToken = jwtDecode(jwtToken)
            const jwtTokenExpirationTime = decodedJwtToken.exp
            return moment().unix() > jwtTokenExpirationTime
        }
        return true
    }
}
