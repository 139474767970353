<template>
    <v-container>
        <div class="table-options">
            <v-btn class="my-2" dark small color="#027a00" @click="createDealer">
                <v-icon dark>mdi-plus</v-icon>Create
            </v-btn>
        </div>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="dealers"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.id }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.firstName }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.lastName }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.email }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import dealerService from '@/services/dealer-service'
    import DeleteButton from '@/components/DeleteButton'

    export default {
        name: 'DealerRoute',
        components: { DeleteButton },
        data: () => {
            return {
                filter: [],
                dealers: [],
                filterFields: [
                    {
                        name: 'First Name',
                        key: 'firstName',
                        type: 'text'
                    },
                    {
                        name: 'Last Name',
                        key: 'lastName',
                        type: 'text'
                    },
                    {
                        name: 'Email',
                        key: 'email',
                        type: 'text'
                    }
                ],
                headers: [
                    { text: 'Id', value: 'id' },
                    {
                        text: 'First Name',
                        value: 'firstName',
                        align: 'left',
                        sortable: true
                    },
                    { text: 'Last Name', value: 'lastName' },
                    { text: 'Email', value: 'email' }
                ]
            }
        },
        watch: {
            $route(to, from) {
                if (!from.name.includes('Dealer')) {
                    this.$store.dispatch('clearFilters')
                }
            }
        },
        created() {
            this.fetchAllDealers()
        },
        methods: {
            handleClick(mode, item) {
                this.$router.push(`/dealers/${item.id}/${mode}`)
            },
            onDelete(item) {
                dealerService.deleteDealerById(item.id).then(() => {
                    this.fetchAllDealers()
                })
            },
            createDealer() {
                this.$router.push(`/dealers/create`)
            },
            fetchAllDealers() {
                dealerService.getAllDealers().then(resp => {
                    this.dealers = resp.data.data
                })
            },
            filterChanged(filterObject) {
                if (Object.keys(filterObject).length > 0) {
                    dealerService.getDealersByFilter(filterObject).then(resp => {
                        this.dealers = resp.data.data
                    })
                } else {
                    this.fetchAllDealers()
                }
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
