<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="card-title px-4 mt-1">
                        Filters
                        <v-btn v-if="$store.getters.currentUser.role === 'admin'" class="my-2 mx-2" dark small color="#027a00" @click="createPartner">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <filters-alternative
                            :sort-options="headers.filter(element => element.sortable)"
                            :filters="filterFields"
                            @reloadFilters="reloadFilters"
                            @filterResults="filterResults"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="partners"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.title }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.available }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.address }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.vatNumbers.length > 0 ? getVatNumbers(props.item.vatNumbers) : '-' }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.type }}</td>
                    <td v-if="!isDealer">
                        <v-btn class="mx-2" dark small :color="props.item.approved ? 'green' : 'red'" outlined @click="toggleApprove(props.item)">
                            <div v-if="props.item.approved">
                                Approved
                            </div>
                            <div v-else>
                                Not Approved
                            </div>
                        </v-btn>
                    </td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button v-if="!isDealer" @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
    </v-container>
</template>

<script>
    import partnerService from '@/services/partner-service'
    import DeleteButton from '@/components/DeleteButton'
    import FiltersAlternative from '@/components/FiltersAlternative'
    import store from '@/store'

    export default {
        name: 'PartnerRoute',
        components: { DeleteButton, FiltersAlternative },
        data: () => {
            return {
                partners: [],
                filterFields: [
                    {
                        text: 'Partner',
                        value: 'partner',
                        type: 'text',
                        searchable: true,
                        data: null
                    }
                ],
                headers: [{
                              text: 'Title',
                              value: 'title',
                              align: 'left'
                          },
                          { text: 'Available', value: 'available' },
                          { text: 'Address', value: 'address' },
                          { text: 'Vat Number', value: 'afm' },
                          { text: 'Type', value: 'type' }
                ],
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                loading: true,
                sortOption: null,
                order: null,
                filters: null
            }
        },
        computed: {
            isDealer() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer'
            }
        },
        watch: {
            page() {
                var path = `/partners?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchFilteredPartners()
            },
            $route(to, from) {
                if (!from.name.includes('Partner')) {
                    this.$store.dispatch('clearFilters')
                }
                if (to !== from) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchFilteredPartners()
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.filters = this.$store.getters.getFilters
            this.sortOption = this.$store.getters.getSortOption
            this.order = this.$store.getters.getSortOrder
        },
        methods: {
            getVatNumbers(vatNumbers) {
                let acc = ''
                vatNumbers.forEach(element => {
                    acc += element.number + ', '
                })
                return acc.slice(0, -2)
            },
            reloadFilters() {
                this.order = 'ASC'
                this.sortOption = null
                this.filters = {}
                this.fetchFilteredPartners()
            },
            filterResults(filterObject, sortOption = null, order = 'ASC', page = false) {
                this.sortOption = sortOption
                this.order = order
                this.filters = filterObject
                this.loading = true
                if (page) {
                    this.page = 1
                }
                partnerService.getPartnersByFilter(this.page, filterObject, sortOption, order).then(resp => {
                    if (this.page > this.pageCount) {
                        this.page = 1
                    }
                    this.pageCount = resp.data.last_page
                    this.partners = resp.data.data
                    this.loading = false
                })
            },
            toggleApprove(partner) {
                if (!partner.id) {
                    return
                }
                partnerService.toggleApproved(partner.id).then(() => {
                    partner.approved = !partner.approved
                })
            },
            handleClick(mode, item) {
                this.$router.push(`/partners/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                partnerService.deletePartnerById(item.id).then(() => {
                    this.fetchFilteredPartners()
                })
            },
            createPartner() {
                this.$router.push(`/partners/create?page=${this.page}`)
            },
            fetchFilteredPartners() {
                this.loading = true
                partnerService.getPartnersByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    this.partners = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
